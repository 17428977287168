.container {
  display: flex;
}

.sidebar {
  background: #0d6efd !important;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: #000;
}
.icon,
.link_text {
  font-size: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wlpage {
  background-color: red;
  min-width: "100%";
  min-height: "100%";
}
.input-group {
  width: 250px;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #0d6efd !important;
  height: 100%;
  position: relative;
  z-index: 101;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #fff !important;
  color: #0d6efd;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #f8f9fa;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  color: white !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: black !important;
  background: white !important;
}

.sidebar-closed {
  min-width: 40px;
}
.auto {
  /* width: auto !important; */
  padding: 0 !important;
}
p {
  margin-bottom: 0 !important;
}
.cards {
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px #dee2e6;
  border: none !important;
  width: 270px;
  background-color: #dee2e6;
}
.img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #0d6efd;
  padding: 8px;
}
.cards:hover {
  background-color: #0d6efd;
  color: white;
}
.table {
  /* border: 1px solid lightgrey; */
}
.recent {
  box-shadow: 0px 0px 10px 0px #dee2e6;
  border: none !important;
}
.adddoctor {
  display: flex;
  gap: 5px;
  padding: 10px;
  background-color: #0d6efd;
  border-radius: 10px;
  color: white;
  float: right;
  margin-bottom: 15px;
}
.adddoctor:hover {
  background-color: white;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

table {
  width: 100%;
  padding: 1em;
  margin: 20px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

thead {
  background-color: #caf0ec;
  font-size: 16px;
}

th {
  text-align: left;
  padding: 5px;
  background-color: 1px solid #ffffff;
  color: #14877c;
}
td {
  text-align: left;
  padding: 5px;
}

tr {
  height: 50px;
}

tr:nth-child(even) {
  background-color: #e0e0e0;
}
.search input {
  padding: 8px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px #dee2e6;
  border: none !important;
}
.search input:focus {
  border: 1px solid rgb(212, 198, 198);
  outline: none;
}
.icon {
  border: none;
  border-radius: 50%;
  font-size: 30px;
  padding: 3px;
}
.edit {
  background: skyblue;
  box-shadow: 0px 0px 10px 1px lightgray;
  border: none;
  border-radius: 50%;
  font-size: 30px;
  padding: 5px;
}
.addprofile {
  width: 160px;
  height: 150px;
  /* border: 1px solid; */
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  box-shadow: 0px 0px 10px 1px lightgrey;
}
.sel {
  width: 460px;
}
.tabs {
  padding: 10px;
  border: 1px solid lightgray;
  margin-right: 15px;
  cursor: pointer;
}
.tabs:hover {
  background-color: #0d6efd;
  color: white;
}
.subcard {
  box-shadow: 0px 0px 10px 1px lightgray;
  padding: 20px;
  border-radius: 15px;
}
.subplancard {
  font-size: 13px;
  background-color: #d3d3d357;
  padding: 25px;
}
.advplan {
  margin-top: 35px;
  box-shadow: 0px 0px 10px 1px lightgray;
  padding: 10px;
  width: 300px;
  border-radius: 15px;
  margin-left: 50px;
}
.advtxt {
  font-size: 13px;
  margin-left: 15px;
}
.clr {
  color: #0d6efd;
}
.save {
  background: #0d6efd;
  width: 140px;
  padding: 5px;
  text-align: center;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.cancle {
  margin-left: 25px;
  color: #0d6efd;
  border-radius: 10px;
  width: 140px;
  padding: 5px;
  border: 1px solid #0d6efd;
  text-align: center;
  cursor: pointer;
}
.changepassword {
  margin-top: 35px;
  box-shadow: 0px 0px 10px 1px lightgray;
  padding: 10px;

  border-radius: 15px;
}
.navo bt.active {
  background-color: #0d6efd;
  color: white;
}
.addhospital {
  width: 300px;
  text-align: center;
  padding: 30px;
  box-shadow: 0px 0px 10px 1px lightgray;
  border-radius: 15px;
}
textarea:focus {
  border: 1px solid #0dcaf0;
  outline: none;
  box-shadow: 0px 0px 0px 3px #0dcaf0;
}
.contact {
  border: 1px solid skyblue;
  width: 200px;
  padding: 10px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.con {
  display: flex;
  justify-content: space-around;
}
.download:hover {
  background-color: #0d6efd;
  color: white;
}
.nav li {
  border-bottom: 3px solid rgba(0, 0, 0, 0);
}
.nav li:hover {
  border-bottom: 3px solid #eee;
}
.nav li.active {
  border-bottom: 3px solid #338ecf;
  background: #eee;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  display: flex;
}
main {
  width: 100%;
  /* padding: 20px; */
}
.sidebar {
  background: #e9ecef  !important;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 23px;
  text-align: center;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 10px;
  margin-top: 28px;
}
.link {
  display: flex;
  color: black;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  font-size: 12px;
}
.link:hover {
  background: #0d6efd;
  color: white;
  transition: all 0.5s;
}
.active {
  background: #0d6efd;
  color: #fff;
}
.icon,
.link_text {
  font-size: 14px;
  text-decoration: none !important;
}
.link_text p {
  text-decoration: none !important;
}
.link_text {
  margin-top: 5px;
}
a {
  text-decoration: none !important;
}
.web {
  width: -webkit-fill-available !important;
}
.apexcharts-menu-icon {
  display: none;
}
.carousel-control-prev-icon {
  display: none !important;
}
.carousel-control-next-icon {
  display: none !important;
}
.apexcharts-toolbar {
  display: none !important;
}
.hspd {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 5px 1px lightslategrey;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
}
.round {
  /* border: 1px solid; */
  background-color: #0d6efd;
  color:white;
  border-radius: 50%;
  padding: 20px;
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.round h6{
  margin-bottom: 0px;
}
.round:hover{
  background-color: #61dafb;

}
.form-control{
  width: 320px;
}
a{
  color: #000;
}